<template lang="pug">
v-card.Zmx-auto(max-width="360")

  v-list(three-line subheader flat)
    v-subheader Смена 1
    v-list-item-group(v-model='selected' active-class='primary--text' multiple='')
      template(v-for='(item, index) in items')
        v-list-item(:key='item.title')
          template(v-slot:default='{ active }')
            v-list-item-avatar(Zrounded)
              v-avatar.grey--text(color="white") ИВ
            v-list-item-content
              v-list-item-title(:class="{'font-weight-bold':active}") {{item.title}}
              v-list-item-subtitle.grey--text {{item.headline}} {{item.subtitle}}
            v-list-item-action
              v-list-item-action-text {{item.action}}
              v-badge(v-if='active' inline color="primary" content="10")
              .ezcar 10.000 ₸
        v-divider(v-if='index < items.length - 1' :key='index')

//- v-list
  template(v-for="(c,index) in orders")
    v-list-item(three-line)
      v-list-item-avatar(Zrounded)
        v-avatar.grey--text(color="white") ИВ
      v-list-item-content
        v-list-item-title Иванов Иван
        v-list-item-subtitle Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг
      v-list-item-action
        v-btn(icon): v-icon(color="grey lighten-1") mdi-menu-right
    v-divider(v-if="index < orders.length - 1" :key="index")
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    selected: [2],
    items: [
      {
        action: '15 мин',
        headline: '',
        subtitle: 'Молоко 2шт, Творог "Толганай", Салтанат 10шт, Хлеб 55шт, Пакет 10шт, Зубилы 1шт,',
        title: 'Чек #123',
      },
      {
        action: '2 час',
        headline: 'Summer BBQ',
        subtitle: 'Wish I could come, but I\'m out of town this weekend.',
        title: 'me, Scrott, Jennifer',
      },
      {
        action: '6 час',
        headline: 'Oui oui',
        subtitle: 'Do you have Paris recommendations? Have you ever been?',
        title: 'Sandra Adams (777) 750 65 25',
      },
      {
        action: '12 час',
        headline: 'Birthday gift',
        subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
        title: 'Trevor Hansen',
      },
      {
        action: '12 час',
        headline: 'Recipe to try',
        subtitle: 'We should eat this',
        title: 'Britta Holt',
      },
    ],
  }),

  computed: {
    ...mapState({
      orders: (state) => state.tjtheme.orders,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus"></style>
