<template lang="pug">
AppLayout
  template(v-slot:header)
    v-spacer
    v-toolbar-title.cash-register-name.mx-2.align-center Вторник 22, 12 2021
      v-chip.ml-2(small) Сегодня
    v-spacer
    v-icon mdi-calendar
  Orders

  template(v-slot:footer)
    v-footer.pa-2(
      app,
      Zabsolute,
      inset,
      bottom,
      padless,
      Zoutlined,
      Zfixed,
      Zcolor="transparent",
      color="#373740",
      width="100%"
    )
      Basket(:icon="false", no-icon) X-Отчет

//- v-list
  template(v-for="(c,index) in orders")
    v-list-item(three-line)
      v-list-item-avatar(Zrounded)
        v-avatar.grey--text(color="white") ИВ
      v-list-item-content
        v-list-item-title Иванов Иван
        v-list-item-subtitle Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг, Молоко 2шт - 200тг
      v-list-item-action
        v-btn(icon): v-icon(color="grey lighten-1") mdi-menu-right
    v-divider(v-if="index < orders.length - 1" :key="index")
</template>
<script>
import AppLayout from 'T@/AppLayout.vue'
import Orders from './orders/Orders.vue'

export default {
  components: {
    AppLayout,
    Orders,
    Basket: () => import('T@/components/cart/Basket.vue'),
  },
}
</script>
<style lang="stylus"></style>
